import React, { useState, useCallback } from 'react';

import {
  SelectInput,
  SelectField,
  AutocompleteInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  Validator,
  useRegisterMutationMiddleware,
  UpdateParams,
  UseUpdateOptions,
  UpdateMutationFunction,
} from 'react-admin';

import {
  Button,
  Box,
  Typography,
  styled,
} from '@mui/material';

import { useFormContext } from 'react-hook-form';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import SelectArrayField from './SelectArrayField';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const getInputComponent = (autocomplete: boolean, multiple: boolean) => {
  if (multiple) {
    if (autocomplete) return AutocompleteArrayInput;
    return SelectArrayInput;
  }

  if (autocomplete) return AutocompleteInput;
  return SelectInput;
};

const EditSelectInput = ({
  source,
  label,
  showEdit,
  choices,
  validate,
  autocomplete = false,
  multiple = false,
}: {
  showEdit: boolean
  source: string
  label: string
  choices: { id: string, name:string }[]
  validate?: Validator | Validator[];
  autocomplete?: boolean
  multiple?: boolean
}) => {
  const [editable, setEditable] = useState(false);

  const { resetField } = useFormContext();

  const handleChange = useCallback(() => {
    const newValueEditable = !editable;
    if (!newValueEditable) resetField(source);
    setEditable(newValueEditable);
  }, [editable, resetField, source]);

  const callback = useCallback(async (
    resource: string | undefined,
    params: Partial<UpdateParams> | undefined,
    options: UseUpdateOptions | undefined,
    next: UpdateMutationFunction,
  ) => {
    const existingSuccessCallback = options?.onSuccess;
    const newSuccessCallback: UseUpdateOptions['onSuccess'] = existingSuccessCallback ? (...args) => {
      setEditable(false);
      return existingSuccessCallback(...args);
    } : () => {
      setEditable(false);
    };
    const newOptions = {
      ...options,
      onSuccess: newSuccessCallback,
    };
    return next(resource, params, newOptions);
  }, []);

  useRegisterMutationMiddleware<UpdateMutationFunction>(callback);

  if (!editable) {
    return (
      <Box>
        <Title>{label}</Title>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          {multiple ? (
            <SelectArrayField source={source} choices={choices} emptyText="-" />
          ) : (
            <SelectField source={source} choices={choices} emptyText="-" />
          )}
          {showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
    );
  }
  const InputComponent = getInputComponent(autocomplete, multiple);
  return (
    <Box>
      <Title>{label}</Title>
      <Box display="flex" alignItems="baseline" justifyContent="space-between" flexWrap="wrap">
        <InputComponent color="secondary" choices={choices} label="" source={source} validate={validate} />
        <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
          Cancel
        </Button>
      </Box>

    </Box>
  );
};

export default EditSelectInput;
