import React from 'react';

import {
  Box, Grid, Typography,
} from '@mui/material';

import { get } from 'lodash';

import {
  useRecordContext,
  TextField,
  TextInput,
  SelectInput,
  NumberInput,
  minValue,
  required,
} from 'react-admin';

import currencies from '../../../constants/currencies';
import { UnitsOfTime, unitsOfTimeChoices } from '../../../constants/unitsOfTime';
import { SignificantTransactionCountriesValue } from '../../kycForm/view/answers/SignificantTransactionCountries';

interface TransactionDetails {
  monthlyVolume?: number;
  monthlyFrequency?: number;
  median?: number;
  max?: number;
  expectedTransactionTimeline?: {
    amount: number;
    unit: UnitsOfTime;
  }
}

export type TransactionMonitoringThresholdsValue = {
  currency: string;
  incoming?: TransactionDetails;
  outgoing?: TransactionDetails;
  process?: TransactionDetails;
  total?: TransactionDetails;
};

export const transactionMonitoringThresholdsFormatter = (
  datapointValue: TransactionMonitoringThresholdsValue,
): SignificantTransactionCountriesValue => {
  const { currency, ...rest } = datapointValue;
  return {
    [currency]: {
      SWE: {
        ...rest,
      },
    },
  };
};

const NestedField = ({
  label,
  source,
  unit,
}: {
  label: string;
  source: string;
  unit?: string;
}) => {
  const record = useRecordContext();

  const value = get(record, source);

  const string = value ? `${value} ${unit ?? ''}`.trimEnd() : '-';

  return (
    <Grid item xs={12} md={6}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{label}</Typography>
        <TextField record={{ string }} source="string" />
      </Box>
    </Grid>
  );
};

const TransactionMonitoringThresholdsInput = ({ source }: { source: string }) => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={currencies.map((curr) => ({ name: curr, id: curr }))}
        label="Currency"
        fullWidth
        source={`${source}.currency`}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Incoming transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.incoming.expectedTransactionTimeline.unit`}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Outgoing transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.outgoing.expectedTransactionTimeline.unit`}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Processed transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.process.expectedTransactionTimeline.unit`}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Total transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.total.expectedTransactionTimeline.unit`}
      />
    </Grid>
  </Grid>
);

export const TransactionMonitoringThresholdsAnswer = () => {
  const record = useRecordContext<{
    value: TransactionMonitoringThresholdsValue
  }>();

  return (
    <Grid container spacing={10}>
      <Grid item container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography color="primary" variant="h6">Incoming transactions</Typography>
        </Grid>
        <NestedField
          source="value.incoming.monthlyVolume"
          label="Monthly volume"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.incoming.max"
          label="Max amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.incoming.median"
          label="Typical amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.incoming.monthlyFrequency"
          label="Number of transactions"
        />
        <NestedField
          source="value.incoming.expectedTransactionTimeline.amount"
          label="Behavior duration"
          unit={record?.value.incoming?.expectedTransactionTimeline?.unit}
        />
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography color="primary" variant="h6">Outgoing transactions</Typography>
        </Grid>
        <NestedField
          source="value.outgoing.monthlyVolume"
          label="Monthly volume"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.outgoing.max"
          label="Max amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.outgoing.median"
          label="Typical amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.outgoing.monthlyFrequency"
          label="Number of transactions"
        />
        <NestedField
          source="value.outgoing.expectedTransactionTimeline.amount"
          label="Behavior duration"
          unit={record?.value.outgoing?.expectedTransactionTimeline?.unit}
        />
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography color="primary" variant="h6">Processed transactions</Typography>
        </Grid>
        <NestedField
          source="value.process.monthlyVolume"
          label="Monthly volume"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.process.max"
          label="Max amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.process.median"
          label="Typical amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.process.monthlyFrequency"
          label="Number of transactions"
        />
        <NestedField
          source="value.process.expectedTransactionTimeline.amount"
          label="Behavior duration"
          unit={record?.value.process?.expectedTransactionTimeline?.unit}
        />
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography color="primary" variant="h6">Total transactions</Typography>
        </Grid>
        <NestedField
          source="value.total.monthlyVolume"
          label="Monthly volume"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.total.max"
          label="Max amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.total.median"
          label="Typical amount"
          unit={record?.value.currency}
        />
        <NestedField
          source="value.total.monthlyFrequency"
          label="Number of transactions"
        />
        <NestedField
          source="value.total.expectedTransactionTimeline.amount"
          label="Behavior duration"
          unit={record?.value.total?.expectedTransactionTimeline?.unit}
        />
      </Grid>
    </Grid>
  );
};

const TransactionMonitoringThresholdsForm = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
        marginBottom={4}
      >
        Transaction Monitoring Thresholds
      </Typography>
      <TransactionMonitoringThresholdsInput source="value" />
    </Grid>
    <Grid item xs={12} md={12} justifyContent="center" display="flex">
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={4}
        helperText={false}
        placeholder="Please explain your decision."
        validate={required()}
      />
    </Grid>
  </Grid>
);

export default TransactionMonitoringThresholdsForm;
