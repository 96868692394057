import React, { useMemo, useState } from 'react';

import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  RaRecord,
  SimpleFormIterator,
  useNotify,
} from 'react-admin';

import {
  Box,
  Radio,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';

import TimelineIcon from '@mui/icons-material/Timeline';

import {
  useFormContext, useWatch,
} from 'react-hook-form';
import { isEmpty } from 'lodash';
import useDialogStatus from '../../../../hooks/useDialogStatus';

import currencies from '../../../../constants/currencies';
import thresholdVariants, { ThresholdVariants } from '../../../../constants/thresholdVariant';

import RiskAdjustment from '../../../../customFields/Charts/RiskAdjustment';

const Body = ({
  getSource,
  displayText,
  onlyRiskBased = false,
}: {
  getSource: ((source: string) => string) | undefined
  displayText: string | undefined
  onlyRiskBased?: boolean,
}) => {
  const { setValue, getValues } = useFormContext();

  const isGlobal = !!getValues(getSource!('global'));

  const [selectedValue, setSelectedValue] = useState(isGlobal ? 'global' : 'riskBased');
  const { open, openDialog, closeDialog } = useDialogStatus();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'global') { setValue(getSource!('riskBased'), null); }
    if (event.target.value === 'riskBased') { setValue(getSource!('global'), null); }
  };

  return (
    <Box>
      {!onlyRiskBased && (
        <>
          <Radio
            checked={selectedValue === 'global'}
            onChange={handleChange}
            value="global"
          />
          <NumberInput
            label="Global"
            source={getSource!('global')}
            size="small"
            helperText={false}
            validate={minValue(1)}
            disabled={selectedValue !== 'global'}
          />
        </>
      )}
      <Radio
        checked={selectedValue === 'riskBased'}
        onChange={handleChange}
        value="riskBased"
      />
      <Button variant="contained" size="small" startIcon={<TimelineIcon />} onClick={openDialog} disabled={selectedValue !== 'riskBased'}>
        Risk-based
      </Button>
      <RiskAdjustment
        displayText={displayText || getValues(getSource!('currency'))}
        source={getSource!('riskBased')}
        open={open}
        closeDialog={closeDialog}
      />
    </Box>
  );
};

const ThresholdInput = ({
  displayText,
  overrideCurrencies,
  allowCommon = false,
  thresholdVariant = thresholdVariants.CURRENCY,
  source,
  onlyRiskBased = false,
}: {
  displayText?: string;
  overrideCurrencies?: string[];
  allowCommon?: boolean;
  thresholdVariant?: ThresholdVariants;
  source: 'default' | 'form';
  onlyRiskBased?: boolean,
}) => {
  const notify = useNotify();
  const thresholds = useWatch({ name: `ruleParameters.${source}.thresholds` });

  const title = source === 'default'
    ? 'Default Threshold'
    : 'Know Your Customer (KYC) Application Form Threshold';
  const subheader = source === 'default'
    ? 'Configure default thresholds to be used within the rule for all viable entities. It can either be an global value that will be applied independent of risk assessment or it can be risk based thresholds.'
    : 'You can optionally set thresholds on completed KYC forms for any entity. A global value may be assigned to all entities, unaffected by their individual risk scores. Alternatively, choosing a risk-based threshold means that acceptable answers can vary, aligned with each entity risk score. For either option, every input is a predetermined percentage of deviation from the initial responses received in the KYC form. These thresholds can be tailored for each currency or a common threshold can be established for all currencies.';

  const currenciesInUse = useMemo(
    () => thresholds?.map((threshold: RaRecord) => threshold.currency),
    [thresholds],
  );

  const onChange = (currency: string) => {
    if (currenciesInUse.includes(currency)) {
      notify(`Duplicated currency: ${currency}`, { type: 'error' });
    }
  };

  return (
    <Box width="100%">
      <Card variant="outlined">
        <CardHeader title={title} subheader={subheader} />
        <CardContent>
          {allowCommon && (
            <Box display="flex" flexDirection="column">
              <Typography variant="label">Threshold variant</Typography>
              <RadioButtonGroupInput
                label={false}
                source={`ruleParameters.${source}.thresholdVariant`}
                defaultValue="currency"
                choices={[
                  { id: 'currency', name: 'Currency' },
                  { id: 'common', name: 'Common' },
                ]}
                size="small"
                helperText={false}
              />
            </Box>
          )}
          {thresholdVariant === 'currency' && (
            <Box>
              <Typography variant="label">Add currency specific thresholds</Typography>
              <ArrayInput source={`ruleParameters.${source}.thresholds`} label={false}>
                <SimpleFormIterator
                  inline
                  disableReordering
                  fullWidth
                  sx={{
                    '& .RaSimpleFormIterator-line': {
                      borderBottom: 'none',
                      paddingY: 2,
                      '& .RaSimpleFormIterator-inline': {
                        rowGap: '1em',
                      },
                    },
                  }}
                >
                  <AutocompleteInput
                    source="currency"
                    choices={
                      currencies
                        .filter(
                          (curr) => isEmpty(overrideCurrencies)
                          || overrideCurrencies?.includes(curr),
                        )
                        .map((curr) => ({ id: curr, name: curr }))
                    }
                    helperText={false}
                    onChange={onChange}
                  />
                  <FormDataConsumer>
                    {({ getSource }) => (
                      <Body
                        displayText={displayText}
                        getSource={getSource}
                        onlyRiskBased={onlyRiskBased}
                      />
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          )}
          {thresholdVariant === 'common' && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="label">Add common threshold</Typography>
              <Body
                displayText={displayText}
                getSource={(input) => `ruleParameters.${source}.commonThreshold.${input}`}
                onlyRiskBased={onlyRiskBased}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ThresholdInput;
