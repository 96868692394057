import React, { useCallback } from 'react';

import {
  RecordContextProvider,
  useGetOne,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { get, isEqual, isNil } from 'lodash';

import fieldStatus, { FieldStatus } from '../../../constants/fieldStatus';

import AnswerTitle, { DisplayValues } from './AnswerTitle';
import { ConflictFields } from '../../../constants/conflictFields';
import conflictFieldsFormatter from '../../conflict/conflictFieldsFormatter';

const FieldWrapper = ({
  source,
  datapoint,
  children,
  label,
  isComplexType,
}: {
  source: string;
  datapoint: ConflictFields | null;
  children: React.ReactElement;
  label: string;
  isComplexType: boolean;
}) => {
  const { id } = useParams();
  const resource = useResourceContext();
  const redirect = useNavigate();

  const kycForm = useRecordContext();

  const { data } = useGetOne<{
    id: string;
    status: FieldStatus;
    selectedValue: unknown;
  }>('data-points/status', { id: `${datapoint}/${kycForm?.entity?.id}/${kycForm?.entity?.type}` }, {
    enabled: !!datapoint && !!kycForm?.entity?.id && !!kycForm?.entity?.type,
  });

  const status = (() => {
    if (!datapoint) return 'no-datapoint';
    if (data?.selectedValue) {
      const formatter = conflictFieldsFormatter[datapoint];
      const formattedValue = formatter ? formatter(data.selectedValue) : data.selectedValue;
      if (isEqual(formattedValue, get(kycForm, source, {}))) return 'resolved';
      return 'selection-different-kyc-value';
    }
    return data?.status;
  })();

  const handelClick = useCallback(() => {
    if (!id) return;

    redirect(`/${resource}/${id}/conflict-resolution?name=${datapoint}`, { state: { label } });
  }, [id, label, datapoint, redirect, resource]);

  if (!status) return null;

  const shouldDisplayOtherSelectedValue = !isNil(data?.selectedValue) && status === 'selection-different-kyc-value';

  return (
    <RecordContextProvider value={{ value: get(kycForm, source, null) }}>
      <Card
        variant="outlined"
        sx={{
          m: 0,
          borderRadius: 1,
          borderColor: status === fieldStatus.CONFLICT ? 'yellow.main' : '',
        }}
      >
        <CardContent sx={{ backgroundColor: 'background.paper', p: 6 }}>
          <Grid container spacing={1}>
            <Grid item xs={10} md={10} display="flex" alignItems="center" minHeight={40}>
              {!isComplexType && (
                <AnswerTitle
                  label={label}
                  status={status}
                  selectedValue={data?.selectedValue}
                  shouldDisplayOtherSelectedValue={shouldDisplayOtherSelectedValue}
                  datapoint={datapoint}
                >
                  {children}
                </AnswerTitle>
              )}
              {isComplexType && (
                <AnswerTitle
                  label={label}
                  status={status}
                  shouldDisplayOtherSelectedValue={false}
                  datapoint={datapoint}
                />
              )}
            </Grid>
            <Grid item xs={2} md={2} justifyContent="end" display="flex">
              {status === fieldStatus.CONFLICT && (
                <Button disableRipple endIcon={<ChevronRightIcon color="primary" />} onClick={handelClick}>
                  <Typography color="primary" fontWeight="bold">Resolve conflict</Typography>
                </Button>
              )}
              {(status !== fieldStatus.CONFLICT && status !== 'no-datapoint') && (
                <IconButton disableRipple onClick={handelClick}>
                  <ChevronRightIcon color="primary" />
                </IconButton>
              )}
            </Grid>
            {isComplexType && (
              <>
                <Grid item xs={12} md={12}>
                  <DisplayValues
                    selectedValue={false}
                    label=""
                    shouldDisplayOtherSelectedValue={shouldDisplayOtherSelectedValue}
                    datapoint={datapoint}
                  >
                    {children}
                  </DisplayValues>
                </Grid>
                {shouldDisplayOtherSelectedValue && (
                  <Grid item xs={12} md={12} my={2}>
                    <DisplayValues
                      selectedValue={data?.selectedValue}
                      label="Currently selected value"
                      shouldDisplayOtherSelectedValue={false}
                      datapoint={datapoint}
                    >
                      {children}
                    </DisplayValues>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </RecordContextProvider>
  );
};

const Answer = ({
  label,
  children,
  source,
  datapoint,
  isComplexType = false,
}: {
  label: string;
  source: string;
  children: React.ReactElement;
  datapoint: ConflictFields | null;
  isComplexType?: boolean;
}) => (
  <Grid item md={12} xs={12}>
    <FieldWrapper
      isComplexType={isComplexType}
      label={label}
      source={source}
      datapoint={datapoint}
    >
      {children}
    </FieldWrapper>
  </Grid>
);

export default Answer;
