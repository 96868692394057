import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  useOnSelectionChange,
  NodeTypes,
  Panel,
  Edge,
  Node,
} from 'reactflow';

import 'reactflow/dist/style.css';

import {
  HttpError,
  LinearProgress, SelectInput, useGetList, useNotify, useResourceContext,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  Typography,
} from '@mui/material';

import {
  isEmpty,
  omit,
} from 'lodash';

import { useParams } from 'react-router-dom';

import { useWatch } from 'react-hook-form';
import Drawer from './Drawer';
import BusinessNode from './BusinessNode';
import IndividualNode from './IndividualNode';
import Params from '../../Params';
import { updateTree } from './utils';
import { entityTypesFromResource } from '../../../../constants/entityTypes';
import AccountNode from './AccountNode';

const nodeTypes = {
  Business: BusinessNode,
  Individual: IndividualNode,
  Account: AccountNode,
};

type Data = {
  id: string,
  edges: Edge<{
    volume: number,
    frequency: number
    grouped: string []
  }>[]
  nodes: Node[]
  hasNextPage: boolean
}

const PER_PAGE = 50;

const SelectionChange = ({
  node,
  setNode,
  currency,
  edge,
  setEdge,
}: {
  setEdges: React.Dispatch<React.SetStateAction<Data['edges']>>
  node?: Node,
  setNode: React.Dispatch<React.SetStateAction<Node | undefined>>
  currency: string
  edge?: Edge
  setEdge: React.Dispatch<React.SetStateAction<Edge | undefined>>
}) => {
  const [open, setOpen] = useState(false);

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      const nodeSelected = nodes.at(0);
      const edgeSelected = edges.at(0);

      if (nodeSelected) {
        setNode(() => nodeSelected);
        setEdge(() => undefined);
        setOpen(true);
      }

      if (edgeSelected) {
        setEdge(() => edgeSelected);
        setNode(() => undefined);
        setOpen(true);
      }
    },
  });

  return <Drawer setOpen={setOpen} currency={currency} node={node} edge={edge} open={open} />;
};

const LoadMoreButton = ({
  isLoading,
  hasNextPage,
  handlePaginate,
}: {
  isLoading: boolean,
  hasNextPage: boolean,
  handlePaginate: () => void
}) => {
  if (!hasNextPage && !isLoading) {
    return null;
  }

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={handlePaginate}
      disabled={isLoading}
    >
      {!isLoading && <Typography>Load more</Typography>}
      {isLoading && <LinearProgress />}
    </Button>
  );
};

const RenderFlow = ({
  data,
  hasNextPage,
  params,
  setPage,
  isLoading,
}: {
  isLoading: boolean,
  data: Omit<Data, 'id' | 'hasNextPage'>,
  hasNextPage: boolean,
  setPage: React.Dispatch<React.SetStateAction<number>>
  params: Params
  setParams: React.Dispatch<React.SetStateAction<Params>>
}) => {
  const [node, setNode] = useState<Node>();
  const [edge, setEdge] = useState<Edge>();
  const [nodes, setNodes, onNodesChange] = useNodesState(data.nodes);
  const [edges, setEdges] = useEdgesState(data.edges);

  const handlePaginate = useCallback(() => {
    setPage((prev) => prev + 1);
  }, [setPage]);

  useEffect(() => {
    if (!isEmpty(data.nodes)) {
      setNodes(() => data.nodes.map((n) => {
        if (node?.id === n.id) {
          return {
            ...n,
            selected: true,
          };
        }
        return n;
      }));
    }
    if (!isEmpty(data.edges)) {
      setEdges(() => data.edges.map((ed) => {
        if (edge?.id === ed.id || [ed.source, ed.target].includes(node?.id ?? '')) {
          return {
            ...ed,
            animated: true,
          };
        }
        return omit(ed, ['animated']) as Edge;
      }));
    }
  }, [data, edge?.id, node?.id, setEdges, setNodes]);

  return (
    <Card sx={{ width: '100%', height: '720px' }} variant="outlined">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodesConnectable={false}
        fitView
        nodeTypes={nodeTypes as NodeTypes}
        onNodesChange={onNodesChange}
        selectNodesOnDrag={false}
        elevateEdgesOnSelect
      >
        <SelectionChange
          setEdges={setEdges}
          node={node}
          edge={edge}
          setEdge={setEdge}
          setNode={setNode}
          currency={params.currency}
        />
        <Panel position="top-right">
          <Box display="flex" gap={2}>
            <SelectInput
              choices={[
                { id: 'volume', name: 'Connection Volume' },
                { id: 'frequency', name: 'Connection Frequency' },
              ]}
              source="_flowSort"
              label="Sort"
            />
          </Box>
        </Panel>
        <Panel position="top-center">
          <LoadMoreButton
            handlePaginate={handlePaginate}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
          />
        </Panel>
        <Background style={{
          backgroundColor: '#f7f9fc',
        }}
        />
        <MiniMap zoomable pannable />
        <Controls />
      </ReactFlow>
    </Card>
  );
};

const HandleFlow = ({
  id,
  nodes,
  setNodes,
  edges,
  setEdges,
  params,
  setParams,
  page,
  setPage,
}: {
  id?: string,
  page: number,
  nodes: Data['nodes']
  setPage: React.Dispatch<React.SetStateAction<number>>
  setNodes: React.Dispatch<React.SetStateAction<Data['nodes']>>
  edges: Data['edges']
  setEdges: React.Dispatch<React.SetStateAction<Data['edges']>>
  params: Params
  setParams: React.Dispatch<React.SetStateAction<Params>>
}) => {
  const resource = useResourceContext();
  const sortField = useWatch({ name: '_flowSort' });
  const [usePrevState, setUsePrevState] = useState(false);
  const notify = useNotify();

  const { data, isLoading } = useGetList<Data>(
    'requests/graph/flow',
    {
      filter: {
        entityId: id,
        entityType: entityTypesFromResource[resource],
        ...params,
      },
      sort: {
        field: sortField ?? 'id',
        order: 'DESC',
      },
      pagination: {
        page,
        perPage: PER_PAGE,
      },
    },
    {
      onError: (e) => {
        notify(e instanceof HttpError && e.status === 400 ? e.message : 'Could not get distribution data', { type: 'error' });
      },
    },
  );

  useEffect(() => {
    setUsePrevState(() => false);
  }, [params]);

  useEffect(() => {
    if (data) {
      updateTree({
        data,
        setEdges,
        setNodes,
        usePrevState,
        setUsePrevState,
        edges,
        nodes,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <RenderFlow
      isLoading={isLoading}
      data={{ nodes, edges }}
      hasNextPage={data ? data[0].hasNextPage : false}
      setPage={setPage}
      params={params}
      setParams={setParams}
    />
  );
};

const Flow = ({
  params,
  setParams,
}: {
  params: Params
  setParams: React.Dispatch<React.SetStateAction<Params>>
}) => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [edges, setEdges] = useState<Data['edges']>([]);
  const [nodes, setNodes] = useState<Data['nodes']>([]);

  return (
    <HandleFlow
      id={id}
      page={page}
      setPage={setPage}
      nodes={nodes}
      setNodes={setNodes}
      edges={edges}
      setEdges={setEdges}
      params={params}
      setParams={setParams}
    />
  );
};

export default Flow;
