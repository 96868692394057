import conflictFields, { ConflictFields } from '../../constants/conflictFields';
import { transactionMonitoringThresholdsFormatter } from './fields/TransactionMonitoringThresholdsForm';

const conflictFieldsFormatter: Record<ConflictFields, ((value: any) => unknown) | undefined> = {
  [conflictFields.REGISTRATION_NUMBER]: undefined,
  [conflictFields.COMPANY_NAME]: undefined,
  [conflictFields.RESIDENCE]: undefined,
  [conflictFields.ADDRESS]: undefined,
  [conflictFields.ACTIVITIES]: undefined,
  [conflictFields.TIN]: undefined,
  [conflictFields.POSTAL_CODE]: undefined,
  [conflictFields.CITY]: undefined,
  [conflictFields.EMAIL]: undefined,
  [conflictFields.PHONE_NUMBER]: undefined,
  [conflictFields.WEBSITE]: undefined,
  [conflictFields.CITIZENSHIP]: undefined,
  [conflictFields.SIGNATORIES]: undefined,
  [conflictFields.INCOME]: undefined,
  [conflictFields.ALTERNATIVE_BENEFICIAL_OWNERS]: undefined,
  [conflictFields.BENEFICIAL_OWNER]: undefined,
  [conflictFields.BENEFICIAL_OWNERS]: undefined,
  [conflictFields.TAX_RESIDENCY]: undefined,
  [conflictFields.TRANSACTION_MONITORING_THRESHOLD_V2]: transactionMonitoringThresholdsFormatter,
  [conflictFields.DATE_OF_BIRTH]: undefined,
  [conflictFields.EMPLOYMENT_STATUS]: undefined,
  [conflictFields.FIRST_NAME]: undefined,
  [conflictFields.MIDDLE_NAME]: undefined,
  [conflictFields.LAST_NAME]: undefined,
  [conflictFields.INDUSTRY]: undefined,
  [conflictFields.IS_FINANCIAL_INSTITUTION]: undefined,
  [conflictFields.LEGAL_FORM]: undefined,
  [conflictFields.OTHER_LEGAL_FORM]: undefined,
  [conflictFields.PRODUCTS]: undefined,
  [conflictFields.PURPOSE]: undefined,
  [conflictFields.SOURCE_OF_FUNDS]: undefined,
  [conflictFields.PEP]: undefined,
  [conflictFields.OWNER_STRUCTURE]: undefined,
  [conflictFields.ROLE]: undefined,
  [conflictFields.OPERATION_START_DATE]: undefined,
  [conflictFields.LISTED_FINANCIAL_MARKET]: undefined,
  [conflictFields.STATE_OWNED]: undefined,
  [conflictFields.PREVIOUS_TURNOVER]: undefined,
  [conflictFields.FORECASTED_TURNOVER]: undefined,
  [conflictFields.SOURCE_OF_WEALTH]: undefined,
};

export default conflictFieldsFormatter;
