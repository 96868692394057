import React, { useState, useEffect, useCallback } from 'react';

import {
  HttpError,
  RaRecord, useGetList,
  useNotify,
} from 'react-admin';

import ReactApexChart from 'react-apexcharts';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';

import { isEmpty, isNil, isNumber } from 'lodash';

import useDialogStatus from '../../../hooks/useDialogStatus';
import useDebounce from '../../../hooks/useDebounce';

import NoResults from '../../layout/NoResults';
import DetailedTransactionTable from '../DetailedTransactionTable';
import Params from '../Params';

import { requestTypes } from '../../../constants/requestTypes';

const useGetGraphData = (
  data: RaRecord[] | undefined,
  onClickGraph: (
    _event: any,
    _chartContext: any,
    config: {
      w: {
        config: {
          series: {
            data: { [x: string]: any; }; }[]; }; };
      dataPointIndex: string | number; }) => void,
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  useEffect(() => {
    if (isEmpty(data)) return;

    const newSeries = data as ReactApexChart['props']['series'];

    const newOptions = {
      colors: ['#388e3c', '#880808', '#FEB019'],
      chart: {
        height: 400,
        events: {
          markerClick: onClickGraph,
        },
        type: 'line',
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 2],
      },
      fill: {
        type: ['solid', 'solid', 'solid'],
        opacity: [0.35, 0.35, 0.35],
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        decimalsInFloat: 0,
      },
      tooltip: {
        y: {
          formatter(value: number, {
            seriesIndex, dataPointIndex, w,
          }: {
            seriesIndex: number,
            dataPointIndex: number,
            w: RaRecord
          }) {
            const {
              y,
              currency: curr,
              frequency,
            } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            if (!isNil(y)) {
              return `${y.toFixed(0)} ${curr} (${frequency})`;
            }
            return 'NaN';
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          rotate: -90,
        },
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [data, onClickGraph]);

  return {
    hasData,
    options,
    series,
  };
};

const RollingSum = ({
  params,
  setParams,
  filter,
}: {
  params: Params,
  setParams: React.Dispatch<React.SetStateAction<Params>>
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    requestType: typeof requestTypes.TRANSACTION;
    transactionTypeConfiguration?: string;
  }
}) => {
  const [selectedData, setData] = useState<RaRecord>();
  const debouncedWindowSize = useDebounce(params.rollingSumWindow, 400);
  const { open, closeDialog, openDialog } = useDialogStatus();
  const notify = useNotify();

  const { data, isLoading } = useGetList(
    'requests/graph/rolling-sum',
    {
      filter: { ...filter, windowSize: debouncedWindowSize },
    },
    {
      enabled: isNumber(params.rollingSumWindow),
      onError: (e) => {
        notify(e instanceof HttpError && e.status === 400 ? e.message : 'Cannot fetch transaction stats', { type: 'error' });
      },
    },
  );

  const onClickGraph = useCallback((
    _event: any,
    _chartContext: any,
    config: { w: { config: { series: { data: { [x: string]: any; }; }[]; }; };
      dataPointIndex: string | number; },
  ) => {
    const dataPoint = config?.w?.config?.series[2]?.data[config?.dataPointIndex];
    setData(() => dataPoint);
    openDialog();
  }, [openDialog]);

  const { hasData, options, series } = useGetGraphData(
    data,
    onClickGraph,
  );

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Rolling Sum"
            action={(
              <Box gap="1rem" display="flex" flexDirection="row">
                <TextField
                  label="Window"
                  type="number"
                  size="small"
                  value={params.rollingSumWindow ?? ''}
                  onChange={
                    (e) => setParams((prev) => ({
                      ...prev,
                      rollingSumWindow: e.target.value ? parseInt(e.target.value, 10) : null,
                    }))
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            )}
          />
          <Divider />
          {hasData && <ReactApexChart options={options} series={series} type="line" height={600} />}
          {!hasData && !isLoading && <NoResults variant="h4" />}
          {!hasData && isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center" margin={4}>
              <CircularProgress />
            </Box>
          )}
        </Card>
      </Grid>
      <DetailedTransactionTable
        open={open}
        closeDialog={closeDialog}
        from={selectedData?.x}
        to={selectedData?.x}
        currency={filter.currency}
        entityId={filter.entityId}
        transactionTypeConfiguration={filter.transactionTypeConfiguration}
      />
    </Grid>
  );
};

export default RollingSum;
