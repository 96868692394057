import React, {
  useCallback, useEffect, useMemo,
} from 'react';

import {
  Identifier,
  RecordContextProvider,
  useGetList,
  useResourceContext,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';
import HistoryIcon from '@mui/icons-material/History';

import {
  useNavigate, useParams, useSearchParams,
} from 'react-router-dom';

import {
  isArray,
  isEmpty,
  isPlainObject,
} from 'lodash';

import ConflictResolutionTitle from './ConflictResolutionTitle';
import ConflictResolutionSources from './ConflictResolutionSources';

import { EntityTypes, entityTypesFromResource } from '../../constants/entityTypes';
import fieldStatus from '../../constants/fieldStatus';
import SelectOne from './SelectOne';
import conflictFieldsLabel from './conflictFieldsLabels';
import conflictFields, { ConflictFields } from '../../constants/conflictFields';
import languages from '../../constants/languages';
import conflictFieldsAnswerFields from './conflictFieldsAnswerField';

const DEFAULT_SX = {
  wordBreak: 'break-all',
  wordWrap: 'normal',
};

type Data = {
  id: Identifier;
  entity: {
    id: string;
    type: EntityTypes;
  };
  selected: boolean;
  updatedAt: Date;
  createdAt: Date;
  name: string;
  value: any;
  sourceNames: string[];
  maximumMatchProbability?: number;
}

const isValidDatapointName = (
  name?: string,
): name is ConflictFields => !!name && (Object.values(conflictFields) as string[]).includes(name);

const ListItem = ({
  item,
  name,
  otherSelected,
}: {
  item: Data;
  name: ConflictFields;
  otherSelected: boolean;
}) => {
  const valueIsComplex = (isArray(item.value) && item.value.every(isPlainObject))
  || isPlainObject(item.value);

  const bgcolor = item.selected ? 'greenGray.main' : 'background.grey';

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={valueIsComplex ? 1 : 0} bgcolor={bgcolor} p={6} justifyContent="space-between" display="flex" alignItems="center">
        <Grid item xs={valueIsComplex ? 12 : 4} md={valueIsComplex ? 12 : 4}>
          <RecordContextProvider value={{ ...item, sx: DEFAULT_SX }}>
            {React.createElement(
              conflictFieldsAnswerFields[name],
              { language: languages.EN },
            )}
          </RecordContextProvider>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2} alignItems="center">
            <Typography variant="h4" color="gray">{item.sourceNames.join(', ') }</Typography>
            <RecordContextProvider value={{ ...item, sx: DEFAULT_SX }}>
              <ConflictResolutionSources />
            </RecordContextProvider>
            <SelectOne id={item.id} selected={item.selected} otherSelected={otherSelected} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ValueList = ({
  data,
  name,
}: {
  data?: Data[],
  name: ConflictFields,
}) => {
  const selected = useMemo(() => data?.filter((item) => item.selected), [data]);

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={2}>
        {data?.map((item) => (
          <Grid item xs={12} md={12} key={item.id}>
            <ListItem
              name={name}
              item={item}
              otherSelected={!isEmpty(selected?.filter((selection) => selection.id !== item.id))}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const ConflictResolutionBody = ({
  name,
  entityId,
  entityType,
}: {
  name: string;
  entityId: string;
  entityType: EntityTypes
}) => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetList<Data>('data-points', { filter: { name, entityId, entityType } });

  const status = useMemo(() => {
    if (!data?.length) return fieldStatus.EMPTY;

    if (data.length > 1) return fieldStatus.CONFLICT;

    return fieldStatus.RESOLVED;
  }, [data]);

  const handelBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (isLoading) return null;

  if (!isValidDatapointName(name)) return null;

  const dataObj = data?.find((dataItem) => dataItem.selected);

  return (
    <Slide in direction="left">
      <Grid container gap={1}>
        <Grid item xs={12} md={12} mb={3}>
          <Box display="flex" alignItems="center">
            <IconButton size="medium" onClick={handelBack}>
              <ArrowBackIosFilled fontSize="medium" color="primary" />
            </IconButton>
            <Typography color="primary" variant="h5">Back to form</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} sx={{ backgroundColor: 'background.paper' }}>
          <Card
            variant="outlined"
            sx={{
              m: 0,
              p: 2,
              height: '100%',
              minHeight: 900,
              borderRadius: 1,
            }}
          >
            <CardContent sx={{ p: 6 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={12}>
                  <ConflictResolutionTitle
                    label={conflictFieldsLabel[name]}
                    status={status}
                    selected={dataObj?.selected}
                  />
                </Grid>
                <ValueList
                  name={name}
                  data={data}
                />
                <Grid item xs={12} md={6}>
                  <Button onClick={() => navigate(`manual-input?name=${name}`)}>+ Add value manually</Button>
                </Grid>
                <Grid item xs={12} md={6} display="flex" justifyContent="end">
                  <Button startIcon={<HistoryIcon />} onClick={() => navigate(`resolution-history?name=${name}`)}>
                    View resolution history
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Slide>
  );
};

const ConflictResolution = () => {
  const resource = useResourceContext();

  const {
    id,
  } = useParams();

  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (
    !name
    || !id
    || !entityTypesFromResource[resource]
  ) return null;

  return (
    <ConflictResolutionBody
      entityId={id}
      entityType={entityTypesFromResource[resource]}
      name={name}
    />
  );
};

export default ConflictResolution;
