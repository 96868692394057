import React, { ReactNode } from 'react';

import {
  ReferenceField,
  useRecordContext,
} from 'react-admin';

import { isNil, get, isArray } from 'lodash';

import { useParams } from 'react-router-dom';

import { Chip, Typography } from '@mui/material';

import entityTypes, { EntityTypes, resourceFromEntityTypes } from '../constants/entityTypes';
import { alpha2ToFlagEmoji } from './CountryFlag';

const INDIVIDUAL_PRIORITY = ['nationalIdentifier', 'firstName+lastName', 'customId', 'id'] as const;
const BUSINESS_PRIORITY = ['name', 'registrationNumber', 'customId', 'id'] as const;
const CAR_PRIORITY = ['registrationNumber', 'vinCode', 'carModel', 'id'] as const;
const ACCOUNT_PRIORITY = ['accountNumber', 'accountName', 'id'] as const;

const PRIORITY = {
  [entityTypes.INDIVIDUAL]: INDIVIDUAL_PRIORITY,
  [entityTypes.BUSINESS]: BUSINESS_PRIORITY,
  [entityTypes.CAR]: CAR_PRIORITY,
  [entityTypes.ACCOUNT]: ACCOUNT_PRIORITY,

};

const isValidField = (value: unknown) => !isNil(value) && value !== 'Unknown' && (!isArray(value) || value.length > 0);

const ChipOrText = ({
  children,
  chip,
  hasLink,
}: {
  children: ReactNode,
  chip: boolean,
  hasLink: boolean,
}) => {
  if (chip) {
    return (
      <Chip
        label={children}
        color="primary"
        sx={hasLink ? { '& .MuiChip-label': { textDecoration: 'underline', color: '#fff' } } : {}}
        clickable={hasLink}
      />
    );
  }
  return <Typography sx={hasLink ? { textDecoration: 'underline' } : {}}>{children}</Typography>;
};

const OutputValue = ({
  chip,
  type,
  hasLink,
}: {
  chip: boolean,
  type: EntityTypes,
  hasLink: boolean
}) => {
  const record = useRecordContext();

  if (!record) return <Typography>Unknown</Typography>;

  let output = 'Unknown';
  let fieldName = 'Unknown';

  PRIORITY[type].some((field) => {
    if (field.includes('+')) {
      const [field1, field2] = field.split('+');
      const combinedValue = `${record[field1]} ${record[field2]}`.trim();

      if (isValidField(record[field1]) && isValidField(record[field2])) {
        output = combinedValue;
        fieldName = field;
        return true;
      }
    }

    if (isValidField(record[field])) {
      output = record[field];
      fieldName = field;
      return true;
    }

    return false;
  });

  if (fieldName === 'registrationNumber' && output !== 'Unknown' && type === entityTypes.BUSINESS) {
    const text = `${alpha2ToFlagEmoji(record.residence)} ${output}`;

    return (
      <ChipOrText chip={chip} hasLink={hasLink}>
        {text}
      </ChipOrText>
    );
  }

  if (Array.isArray(output) && output.length > 0 && fieldName === 'nationalIdentifier') {
    const text = (output as Array<{country: string, identifier: string}>).slice(0, 2)
      .map((item, index, array) => `${alpha2ToFlagEmoji(item.country)} ${item.identifier}${index < array.length - 1 ? ', ' : ''}`);

    return (
      <ChipOrText chip={chip} hasLink={hasLink}>
        {text}
        {output.length > 2 && '...'}
      </ChipOrText>
    );
  }

  return <ChipOrText chip={chip} hasLink={hasLink}>{output}</ChipOrText>;
};

const ReferenceEntityField = ({
  source,
  label,
  chip = false,
  linkType = 'edit',
  emptyText = '-',
}: {
  linkType?: string | false;
  source: string;
  chip?: boolean;
  label?: string;
  sortable?: boolean;
  emptyText?: string;
}) => {
  const { id } = useParams();
  const record = useRecordContext();
  const value = source ? get(record, source, {}) : record;
  const idSource = source ? `${source}.id` : 'id';

  const link = id === value.id ? false : linkType;

  if (!value.type) return <Typography>{emptyText}</Typography>;

  return (
    <ReferenceField
      label={label}
      source={idSource}
      reference={resourceFromEntityTypes[value.type as EntityTypes]}
      link={link}
    >
      <OutputValue chip={chip} type={value.type} hasLink={!!link} />
    </ReferenceField>
  );
};

export default ReferenceEntityField;
