import React, { useMemo } from 'react';

import {
  FunctionField,
  useRecordContext,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Divider,
} from '@mui/material';

import { get, isEmpty, isString } from 'lodash';

import { getName } from 'i18n-iso-countries';
import PepTitle from './PepTitle';
import { PepMatches } from '../../../../../../utilities/schemas/pepMatches';
import languages from '../../../../../../constants/languages';
import pepRelations from '../../../../../../constants/pepRelations';
import NoResults from '../../../../../layout/NoResults';
import { pepRoleEnLabels } from '../../../../../../constants/pepRoles';

type PepRole = PepMatches['potentialMatches'][number]['pepRoles'][number]

const getRole = ({
  role,
  roleDescription,
  country,
  fromDate,
  toDate,
}: PepRole) => {
  const countryName = isString(country) && !isEmpty(country) ? getName(country, languages.EN) : '-';
  const roleName = isString(role) && !isEmpty(role) ? get(pepRoleEnLabels, role, 'Unkown role') : '-';
  const roleDescriptionText = isString(role) && !isEmpty(role) ? roleDescription : '-';
  const fromDateText = isString(fromDate) && !isEmpty(fromDate) ? new Date(fromDate).toLocaleDateString() : '-';
  const toDateText = isString(toDate) && !isEmpty(toDate) ? new Date(toDate).toLocaleDateString() : '-';

  return [
    `Role: ${roleName}`,
    `Description: ${roleDescriptionText}`,
    `Country: ${countryName}`,
    `From: ${fromDateText}`,
    `To: ${toDateText}`,
  ].join('\n');
};

const PepRoles = () => {
  const record = useRecordContext<PepMatches['potentialMatches'][number]>();

  const directPepRoles = useMemo(
    () => record?.pepRoles?.filter((role) => role.relation === pepRelations.I_AM_PEP),
    [record],
  );

  const associatedPepRoles = useMemo(
    () => record?.pepRoles?.filter((role) => role.relation !== pepRelations.I_AM_PEP),
    [record],
  );

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="PEP roles" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {isEmpty(record.pepRoles) && <NoResults text="No data to display." icon={null} />}
            {!isEmpty(directPepRoles) && (
              <Grid item xs={12} md={12}>
                <Box>
                  <PepTitle>Direct PEP roles</PepTitle>
                  <FunctionField
                    sx={{ whiteSpace: 'pre' }}
                    source="pepRoles"
                    record={{ pepRoles: directPepRoles }}
                    render={(item: PepMatches['potentialMatches'][number]) => {
                      if (isEmpty(item.pepRoles)) return '-';
                      return (
                        <ul>
                          {record.pepRoles
                            .map((role) => (
                              <li style={{ marginTop: 6 }}>
                                {getRole(role)}
                              </li>
                            ))}
                        </ul>
                      );
                    }}
                  />
                </Box>
              </Grid>
            )}
            {!isEmpty(associatedPepRoles) && (
              <Grid item xs={12} md={12}>
                <Box>
                  <PepTitle>Associated PEP roles</PepTitle>
                  <FunctionField
                    sx={{ whiteSpace: 'pre' }}
                    source="pepRoles"
                    record={{ pepRoles: associatedPepRoles }}
                    render={(item: PepMatches['potentialMatches'][number]) => {
                      if (isEmpty(item.pepRoles)) return '-';
                      return (
                        <ul>
                          {record.pepRoles
                            .map((role) => (
                              <li style={{ marginTop: 6 }}>
                                {getRole(role)}
                              </li>
                            ))}
                        </ul>
                      );
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PepRoles;
