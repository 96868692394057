import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form } from 'react-admin';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import useDialogStatus from '../../hooks/useDialogStatus';
import Params from './Params';
import TransactionTypeConfigurationInput from '../../customFields/TransactionTypeConfigurationInput';

const TransactionTypeFilter = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  const { getValues, setValue } = useFormContext<Params>();
  const { transactionTypeConfiguration } = getValues();

  const handleSubmit = useCallback((data: {
    transactionTypeConfiguration?: {
      or: {
        and: {
          inclusion: 'included' | 'excluded',
          transactionTypeId?: string;
        }[];
      }[];
    };
  }) => {
    setValue('transactionTypeConfiguration', JSON.stringify(data.transactionTypeConfiguration));
    closeDialog();
  }, [closeDialog, setValue]);

  const handleClear = useCallback(() => {
    setValue('transactionTypeConfiguration', undefined);
    closeDialog();
  }, [closeDialog, setValue]);

  return (
    <>
      <Button
        startIcon={<PlaylistAddIcon />}
        variant="text"
        onClick={openDialog}
      >
        {`Filter transaction types (${transactionTypeConfiguration ? 1 : 0})`}
      </Button>
      <Dialog scroll="body" open={open} onClose={closeDialog} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Select transaction type configuration
            <IconButton onClick={closeDialog}>
              <Cancel />
            </IconButton>
          </Box>
        </DialogTitle>
        <Form
          values={{
            transactionTypeConfiguration: transactionTypeConfiguration
              ? JSON.parse(transactionTypeConfiguration)
              : undefined,
          }}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Paper variant="outlined" elevation={0} sx={{ overflow: 'auto' }}>
              <Box padding={4}>
                <Typography variant="mediumTitle" marginBottom={2} component="p">
                  Include any transaction that:
                </Typography>
                <TransactionTypeConfigurationInput source="transactionTypeConfiguration" />
              </Box>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClear}>Clear filter</Button>
            <Button type="submit" variant="contained">Apply filter</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default TransactionTypeFilter;
