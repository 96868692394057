import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import {
  HttpError,
  Labeled,
  NumberField,
  RecordContextProvider,
  useGetList,
  useNotify,
} from 'react-admin';

import Params from './Params';
import { requestTypes } from '../../constants/requestTypes';

const Summary = ({
  params,
  filter,
}: {
  params: Params;
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    requestType: typeof requestTypes.TRANSACTION;
  }
}) => {
  const notify = useNotify();
  const { data } = useGetList('requests/graph/summary', {
    filter,
  }, {
    enabled: !!params.from && !!params.to && !!params.currency,
    onError: (e) => {
      notify(e instanceof HttpError && e.status === 400 ? e.message : 'Cannot fetch transaction summary', { type: 'error' });
    },
  });

  const record = useMemo(() => {
    if (!data) return {};

    const stats = data[0];

    return {
      ...stats,
      netVolume: stats.incoming.volume - stats.outgoing.volume,
    };
  }, [data]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader title="Summary" />
          <Divider />
          <CardContent>
            <RecordContextProvider value={record}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="incoming.volume" options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="outgoing.volume" options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="processing.volume" options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="total.volume" options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="netVolume" options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="incoming.frequency" label="Number incoming transactions" />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="outgoing.frequency" label="Number outgoing transactions" />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="processing.frequency" label="Number processing transactions" />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Labeled>
                    <NumberField source="total.frequency" label="Total number transactions" />
                  </Labeled>
                </Grid>
              </Grid>
            </RecordContextProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Summary;
